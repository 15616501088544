.chathistory_section {
  /* margin-top: -350px; */
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.userCard {
  background-color: #fff;
  width: 300px;
  padding: 5px 18px;
  border-radius: 10px;
  margin-bottom: 12px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  /* width: 50%; */
  border: none;
}

.userCard:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.userName {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 600;
  color: #333;
}
