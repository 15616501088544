@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.cartBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cartIcon {
  font-size: 40px;
  color: #ff6b6b;
}

.cartText {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.header {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  /* border-bottom: 1px solid hsla(0, 0%, 100%, 0.2); */
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logo img {
  vertical-align: middle;
  height: 40px;
  width: 150px;
}

.header .menu .head {
  display: none;
}

.header .menu ul {
  list-style: none;
  /* display: flex; */
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header .menu > ul > li {
  display: inline-block;
}

.header .menu > ul > li:not(:last-child) {
  margin-right: 40px;
}

.header .menu a {
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: hsl(0, 2%, 20%);
  line-height: 1.5;
  margin-top: 15px;
  display: block;
}

.header .menu > ul > li > a {
  padding: 15px 0;
}

.header .menu .dropdown {
  position: relative;
}

.header .menu > ul > .dropdown > a {
  padding-right: 16px;
}

.header .menu i {
  font-size: 15px;
  pointer-events: none;
  user-select: none;
  position: absolute;
  color: hsl(0, 2%, 20%);
  top: calc(50% - 2px);
}

.header .menu > ul > li > i {
  right: 0;
}

.header .menu .sub-menu {
  position: absolute;
  top: 100%;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  left: -150px;
  width: 230px;
  padding: 15px 0;
  background-color: white;
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.5);
  z-index: 1;
  transform-origin: top;
  transform: scaleY(0);
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: self-start;
}

.header .menu li:hover > .sub-menu {
  opacity: 1;
  transform: none;
  visibility: visible;
  transition: all 0.5 ease;
}

.header .menu .sub-menu a {
  padding: 6px 24px;
  margin-bottom: -10px;
}

.header .menu .sub-menu span {
  background-image: linear-gradient(hsl(22, 95%, 50%), hsl(27, 94%, 49%));
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: 0 100%;
  transition: background-size 0.5s ease;
}

.header .menu .sub-menu li:hover > a > span {
  background-size: 100% 1px;
}

.header-right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.header-right > * {
  margin-left: 20px;
}

.header-right .icon-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: hsl(0, 2%, 20%);
}

.header-right .icon-btn:hover {
  color: rgb(248, 117, 69);
}

.header-right .login-btn {
  border: none;
  padding: 4px 12px;
  font-size: 14px;
  color: white;
  background: #cd5702;
  border-radius: 5px;
  transition: 0.3s all ease-out;
  cursor: pointer;
}

.header-right .login-btn:hover {
  color: white;
  background: rgb(243, 32, 5);
}

.header-right .open-menu-btn {
  display: none;
}

.download-btn {
  outline: none;
  border: none;
  font-weight: 600;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  background: linear-gradient(
      120deg,
      #0000 33%,
      hsla(0, 0%, 100%, 0.5) 50%,
      #0000 66%
    ),
    linear-gradient(90deg, #ff9800, #ff5757);
  background-size: 250% 250%, 100% 100%;
  cursor: pointer;
  animation: pujaDetails 2s infinite;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

@keyframes pujaDetails {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

.profile-menu {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.userimg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #5a5c5a;
  transition: border-color 0.3s;
  object-fit: cover;
}

.userimg:hover {
  border-color: #f56709;
}

.userimg1 {
  border-radius: 100px;
  height: 90px;
  margin-bottom: 10px;
  margin-left: 55px;
  margin-right: 55px;
  object-fit: cover;
  border: 1px solid orange;
  /* margin: auto; */
  width: 90px;
}

.profile-info {
  margin-left: 10px;
}

.user-name {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #333;
  text-align: center;
  margin-bottom: -8px;
}

.edit_icon {
  color: rgb(71, 71, 194);
  font-size: 16px;
  margin-left: 5px;
  /* margin-bottom: 2.2px; */
}

.profile-dropdown {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 250px;
}

.user-mobile {
  font-size: 14px;
  margin: 5px 0;
  color: #666;
  text-align: center;
}
.user-balance,
.user-booking {
  font-size: 15px;
  margin: 5px 0;
  color: #2e2e2e;
  font-weight: 500;
}

.user-booking span {
  color: rgb(109, 109, 109);
  font-size: 14px;
}

.user-balance span {
  color: rgb(109, 109, 109);
  font-size: 14px;
}

.separator {
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.view-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  width: 100%;
}

.view-button:hover {
  background-color: #d32f2f;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  width: 100%;
}

.logout-button:hover {
  background-color: #d32f2f;
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
}

.cart-icon:hover {
  color: #007bff;
}

.cart-dropdown {
  position: absolute;
  right: 75px;
  top: 65px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  width: 300px;
  padding: 20px;
  z-index: 1000;
}

.cart-dropdown h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
  color: #333;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.cart-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cart-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.cart-dropdown-item img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
}

.cart-item-details {
  margin-left: 15px;
  flex-grow: 1;
}

.cart-item-details p {
  margin: 0;
  color: #555;
}

.cart-item-details p:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.cart-item-details p:last-child {
  font-size: 14px;
  color: #999;
  margin-top: 4px;
}

.view-cart-btn {
  display: block;
  text-align: center;
  margin-top: 15px;
  padding: 6px 10px;
  background-color: #ff6600;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.view-cart-btn:hover {
  background-color: #ff3300;
}

.cart-dropdown-item:hover {
  background-color: #fafafa;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  padding: 10px;
}

.cart-item-count {
  position: absolute;
  top: 12px;
  background-color: #ff5733;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.cart-modal {
  position: fixed;
  top: 0;
  left: -30px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.cart-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  position: relative;
  max-height: 80vh;
  overflow-y: hidden;
}

.cartmodal-heading {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.close-cart-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  color: #000;
  cursor: pointer;
}

.cart-items-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 20px;
  padding-right: 10px;
}

.cart-modal-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cart-modal-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .cart-items-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .cart-items-list {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1280px) {
  .profile-dropdown {
    right: 0px;
  }
}

@media (max-width: 1024px) {
  .profile-dropdown {
    right: 15px;
  }
}

@media (max-width: 991px) {
  .header {
    padding: 12px 0;
  }

  .header .menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 320px;
    height: 100%;
    background-color: white;
    padding: 15px 30px 30px;
    overflow-y: auto;
    z-index: 1;
    transform: translateX(100%);
    transition: transform 0.5s ease;
  }

  .header .menu.open {
    transform: none;
  }

  .header .menu .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  .header .menu .close-menu-btn {
    height: 35px;
    width: 35px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .header .menu .close-menu-btn::before,
  .header .menu .close-menu-btn::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: hsl(0, 0%, 9%);
  }

  .header .menu .close-menu-btn::before {
    transform: rotate(45deg);
  }

  .header .menu .close-menu-btn::after {
    transform: rotate(-45deg);
  }

  .header .menu > ul > li {
    display: block;
  }

  .header .menu > ul > li:not(:last-child) {
    margin-right: 0;
  }

  .header .menu li {
    border-bottom: 1px solid hsla(0, 7%, 8%, 0.25);
  }

  .header .menu li:first-child {
    border-top: 1px solid hsla(0, 2%, 8%, 0.25);
  }

  .header .menu > ul > li > a {
    padding: 12px 0;
  }

  .header .menu > ul > .dropdown > a {
    padding-right: 34px;
  }

  .header .menu i {
    height: 34px;
    width: 34px;
    border: 1px solid hsla(0, 2%, 8%, 0.25);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    cursor: pointer;
    top: 7px;
  }
  .header .menu .dropdown.active > i {
    background-color: hsla(0, 2%, 8%, 0.25);
    transform: rotate(180deg);
  }

  .header .menu .sub-menu {
    position: static;
    opacity: 1;
    transform: none;
    visibility: visible;
    padding: 0;
    transition: none;
    box-shadow: none;
    width: 100%;
    display: none;
  }

  .header .menu .dropdown.active .sub-menu {
    display: block;
  }

  .header .menu .sub-menu li:last-child {
    border: none;
  }

  .header .menu .sub-menu a {
    padding: 12px 12px 15px;
  }

  .header .menu .sub-menu span {
    background-image: none;
  }

  .header .menu .sub-menu i {
    transform: none;
    right: 0;
  }

  .header-right .open-menu-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
  }

  .header-right .open-menu-btn .line {
    height: 2px;
    width: 30px;
    background-color: hsl(0, 0%, 9%);
    position: absolute;
  }

  .header-right .open-menu-btn .line-1 {
    transform: translateY(-8px);
  }

  .header-right .open-menu-btn .line-3 {
    transform: translateY(8px);
  }

  .cart-dropdown {
    right: 15px;
  }
}

@media (max-width: 991px) {
  .login-btn {
    margin-top: 6px;
    margin-right: -20px;
  }

  .icon-btn {
    margin-right: -15px;
  }
}

@media only screen and (max-width: 480px) {
  .profile-dropdown {
    right: -80px;
  }
  .download-btn {
    border-radius: 5px;
    margin-top: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -20px;
    font-size: 9px;
    font-weight: 400;
  }
  .header .logo img {
    height: 30px;
    width: 105px;
  }
  .profile-menu {
    margin-top: 5px;
  }
  .header-right .icon-btn {
    margin-top: 5px;
  }
}
