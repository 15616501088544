a {
  list-style: none;
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  top: 0 !important;
}


