.vivah-form-container {
    max-width: 1000px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 10px;
}

.form-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-box {
  flex: 1;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.form-box h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 80px;
  resize: none;
}

.vivah-info {
  margin-top: 20px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.vivah-info h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.vivahsubmit_btn {
  background-color: #cd5702;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 40px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  /* width: 100%; */
  margin-top: 10px;
  /* margin-bottom: 20px; */
}

.vivahsubmit_btn:hover {
  background: #e54b00;
}

@media (max-width: 768px) {
  .form-section {
    flex-direction: column;
  }
}
