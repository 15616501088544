.registrationsuccess_page {
  background-color: #f4f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
}

.registration_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.registration_box {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.successimg {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.maincontent {
  font-size: 24px;
  font-weight: bold;
  color: #28a745;
  margin-bottom: 15px;
}

.subcontent {
  font-size: 16px;
  color: #6c757d;
  margin-top: 10px;
}

.registration_box:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.registrationsuccess_page::before {
  content: "";
  position: absolute;
  background: linear-gradient(45deg, #1c74bb, #42b3f5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.1;
  animation: fadeIn 2s ease-in-out infinite alternate;
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.4;
  }
}
