/* OrderTracking.css */

.order-tracking-container {
    max-width: 800px;
    margin: 25px auto;
    font-family: Arial, sans-serif;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .order-tracking-container .title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .order-tracking-container .order-tracking-summary {
    display: flex;
    justify-content: space-between;
    background: #f8f8f8;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .order-tracking-container .summary-tracking-item {
    text-align: center;
  }
  
  .order-tracking-container .summary-tracking-item span {
    display: block;
    font-size: 12px;
    color: gray;
  }
  
  .order-tracking-container .summary-tracking-item strong {
    font-size: 16px;
  }
  
  .order-tracking-container .order-tracking-status {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .order-tracking-container .status.shipped {
    color: green;
    font-weight: bold;
  }
  
  .order-tracking-container .progress-tracking-bar {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    padding: 10px 0;
    position: relative;
    flex-wrap: wrap;

  }

  
  
  .order-tracking-container.step {
    text-align: center;
    position: relative;
    font-size: 14px;
  }
  .order-tracking-container .tracking-step{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;

}
 

.order-tracking-container  .icon {
    font-size: 24px;
    margin-right: 8px;
  }
  
  .order-tracking-container  .icon.success {
    color: green;
  }
  
  .order-tracking-container  .icon.in-progress {
    color: gray;
  }
  
  .order-tracking-container  .icon.error {
    color: red;
  }
  
  .order-tracking-container .step.pending {
    color: gray;
  }
  
  .order-tracking-container .shipping-info {
    background: #f8f8f8;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .order-tracking-container .shipping-info h3 {
    margin-bottom: 10px;
  }
  
  .order-tracking-container .shipping-info p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .order-tracking-container .shipping-info a {
    color: blue;
    text-decoration: none;
  }
  
  .order-tracking-container .map-container iframe {
    border: none;
    border-radius: 5px;
  }
  