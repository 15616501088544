.product_title {
  color: #292929;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1rem;
  margin-top: -30px;
}

.product_desc {
  font-size: 15px;
  color: #2e2e2e;
  margin-top: -10px;
  margin-bottom: 10px;
}

.product-img {
  width: 100%;
  height: 240px;
  margin-top: 40px;
  border-radius: 5px;
  scroll-snap-type: x mandatory;
  /* overflow-x: scroll; */
  overflow-y: hidden;
  white-space: nowrap;
}

.product-img img {
  width: auto;
  height: 300px;
  scroll-snap-align: start;
}

/* .additional-image {
  height: 200px;
  width: 200px;
} */

.product .product-tabs {
  width: 100%;
  height: auto;
  /* background-color: rgb(248, 117, 69); */
}

.product .product-tabs .product-link {
  border-radius: 0px;
  border: none;
  margin-bottom: 0;
}

.product-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.product .product-tabs .product-link.active {
  background-color: #f52a07;
  color: #fff;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 3px;
  padding-right: 20px;
  padding-left: 20px;
  /* padding: 20px; */
  font-weight: 600;
}

.product-content {
  padding-top: 1rem;
  padding-bottom: 40px;
}

.package-form {
  padding: 25px;
  margin-top: 40px;
  border-radius: 8px;
  border: 2px dashed gray;
}

.product-btn {
  background-color: rgb(248, 117, 69);
  /* padding: 10px; */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  font-weight: 600;
}

.product-btn:hover {
  background-color: #f52a07;
}

.stock-status {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.in-stock {
  color: green;
}

.out-of-stock {
  color: red;
}

@media (max-width: 480px) {
  .product-img img {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    scroll-snap-align: start;
  }
  .product_title {
    color: #292929;
    margin-top: -35px;
    font-size: 25px;
    /* font-family: "Alegreya SC", serif; */
    text-align: left;
    margin-bottom: 1rem;
  }
  .product_desc {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .package-form {
    margin-top: 20px;
  }
}
