.grihapravesh-form-container {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  /* background: #f9f9f9; */
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.grihapravesh-form-container h2 {
  text-align: center;
  color: #333;
  font-size: 25px;
  margin-bottom: 40px;
}

.grihamuhurat_form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

/* .form-group:last-child {
  flex: 1 1 calc(33.33% - 10px);
} */

.form-group {
  flex: 1 1 calc(33.33% - 10px);
  display: flex;
  flex-direction: column;
}

.grihamuhurat_label {
  font-size: 14px !important;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

input,
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
}

.grihapravesh_btn {
  background-color: #cd5702;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 40px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.grihapravesh_btn:hover {
  background: #e68900;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    flex: 1 1 100%;
  }
}
