.addUserFormpandit .inputGroup {
  width: 60%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  margin-left: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgb(248, 244, 244);
  /* align-items: center; */
}

.addUserFormpandit .inputGroup input {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #bbb5b5;
  border-radius: 10px;
}

.profileImageLabelpandit img {
  /* display: block; */
  border-radius: 50%;
  border: 2px solid #ccc;
  padding: 5px;
}

.panditform_heading {
  font-size: 16px;
  color: #fc6908;
  font-weight: 600;
}
